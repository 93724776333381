import engLang from '@/images/language/eng_lang.svg';
import filLang from '@/images/language/fil_lang.svg';
import fraLang from '@/images/language/fra_lang.svg';
import gerLang from '@/images/language/ger_lang.svg';
import itaLang from '@/images/language/ita_lang.svg';
import porLang from '@/images/language/por_lang.svg';
import spaLang from '@/images/language/spa_lang.svg';
import indLang from '@/images/language/ind_lang.svg';
export interface ILanguage {
  code2: string;
  language: string;
  img: string;
}

export enum LanguageType {
  spanish = 'es',
  portuguese = 'pt',
  deutsch = 'de',
  english = 'en',
  french = 'fr',
  italian = 'it',
  filipino = 'fil',
  indonesian = 'id',
}

export const languages: ILanguage[] = [
  {
    code2:    'en',
    language: 'English',
    img:      engLang,
  },
  {
    code2:    'es',
    language: 'Español',
    img:      spaLang,
  },
  {
    code2:    'fr',
    language: 'Français',
    img:      fraLang,
  },
  {
    code2:    'de',
    language: 'Deutsch',
    img:      gerLang,
  },
  {
    code2:    'it',
    language: 'Italiano',
    img:      itaLang,
  },
  {
    code2:    'pt',
    language: 'Português',
    img:      porLang,
  },
  {
    code2:    'fil',
    language: 'Filipino',
    img:      filLang,
  },
  {
    code2:    'id',
    language: 'Bahasa Indonesia',
    img:      indLang,
  },
];
