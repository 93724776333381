import {
  useQuery,
  useMutation,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query';

import { userApi } from '@/entities/user/api';
import { IUserPreferences } from '@/entities/user/model/types';

export const useUserPreferences = (userId: number | undefined): UseQueryResult<IUserPreferences> => useQuery({
  queryKey: ['userPreferences', userId],
  queryFn:  () => userApi.getUserPreferences(userId!),
  retry:    false,
  enabled:  !!userId,
});

export const useSetUserPreferences = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      data,
    }: {
      userId: number;
      data: Partial<IUserPreferences>;
    }) => userApi.setUserPreferences(userId, data),
    retry:     false,
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries({ queryKey: ['userPreferences', userId] }).catch(e => console.error(e));
    },
  });
};
