import React, { FC, Suspense } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';

import './App.scss';
import { AppRoutes } from './AppRoutes';
import { globalColors } from './globalColors';
import './i18n';
import AuthRoutes from './modules/Auth/AuthRoutes';
import { SubscriptionPlanRoutes } from './modules/Profile/SubscriptionPlanRoutes';
import { Loader } from './modules/shared';
import ErrorFallback from './modules/shared/ErrorFallback/ErrorFallback';
import { analyticsService } from './services/analytics.service';

analyticsService.init();

const theme = {
  colors: globalColors,
};

const App: FC = () => {
  const gtmParams = {
    id: process.env.REACT_APP_GTM_ID as string,
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <HelmetProvider>
        <GTMProvider state={gtmParams}>
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <ThemeProvider theme={theme}>
                <Switch>
                  <Route
                    path="/auth"
                    component={AuthRoutes}
                  />
                  <Route
                    path="/profile/plan-subscription"
                    component={SubscriptionPlanRoutes}
                  />
                  <Route
                    path="*"
                    component={AppRoutes}
                  />
                </Switch>
              </ThemeProvider>
            </Suspense>
          </BrowserRouter>
        </GTMProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
