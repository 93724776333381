import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { userApi } from '@/entities/user/api';

import { userDataQuery } from '../../../../../context/atoms/User';

const QUERY_KEY = 'satisfaction-survey';

export function useTrustpilotTourQuery() {
  const userData = useRecoilValue(userDataQuery);
  const queryClient = useQueryClient();
  const growthbook = useGrowthBook();
  const growthbookGuideTrustpilotTourValue = useFeatureValue(
    'guide_trustpilot_tour_value',
    'guide_trustpilot_tour_value_v1',
  );

  const guideTrustpilotTourValueFlag = useQuery({
    queryKey: [QUERY_KEY, growthbookGuideTrustpilotTourValue],
    queryFn:  () => userApi
      .getTourPassed(userData?.id, growthbookGuideTrustpilotTourValue)
      .then(data => data.passed),
    staleTime: Infinity,
    enabled:   Boolean(growthbook?.ready && userData?.id),
  });

  const guideTrustpilotTourValueFlagMutation = useMutation({
    mutationKey: [QUERY_KEY, growthbookGuideTrustpilotTourValue],
    mutationFn:  () => userApi.postTourPassed(userData?.id, growthbookGuideTrustpilotTourValue),
    onSuccess() {
      return queryClient.setQueryData(
        [QUERY_KEY, growthbookGuideTrustpilotTourValue],
        true,
      );
    },
  });

  const isGuideTrustpilotTourVisible =    !guideTrustpilotTourValueFlag.isLoading
    && !guideTrustpilotTourValueFlag.data;

  function setGuideTrustpilotTourVisible() {
    if (isGuideTrustpilotTourVisible) {
      guideTrustpilotTourValueFlagMutation.mutate();
    }
  }

  return {
    userEmail:           userData?.email,
    isGuideReviewPassed: guideTrustpilotTourValueFlag.data,
    isGuideTrustpilotTourVisible,
    setGuideTrustpilotTourVisible,
  };
}
