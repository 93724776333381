import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

import haveAccountImage from '@/modules/Auth/Signup/WarningModal/assets/svg/have_account_image.svg';
import { analyticsService } from '@/services/analytics.service';

import { DialogContainer, Wrapper } from './WarningModal.styles';

interface IWarningModalProps {
  warningModalState: boolean;
  closeWarningModal: () => void;
}

export const WarningModal = ({
  warningModalState,
  closeWarningModal,
}: IWarningModalProps) => {
  const { t } = useTranslation();
  const navigation = useHistory();

  function handleClose() {
    closeWarningModal();
    analyticsService.sendEvent('Signup_AccountExists_SignInButton_Click');

    navigation.push('/auth/signin');
  }

  useEffect(() => {
    if (!warningModalState) {
      return;
    }

    analyticsService.sendEvent('Signup_AccountExists_MainModal_View');
  }, [warningModalState]);

  return (
    <Modal
      open={warningModalState}
      disableAutoFocus
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <DialogContainer>
          <img
            src={haveAccountImage}
            alt="Person emoji illustration in a blue circle"
          />
          <p
            className="my-4 text-[#24234C] text-center text-lg font-bold leading-[30px]"
          >
            {t('auth.warning-modal.title')}
          </p>
          <p
            className="mb-4 text-[#6B7280] text-center text-sm font-medium leading-[21px]"
          >
            {t('auth.warning-modal.description')}
          </p>
          <button
            type="button"
            className="btn-confirm"
            onClick={handleClose}
          >
            {t('Sign in')}
          </button>
        </DialogContainer>
      </Wrapper>
    </Modal>
  );
};
