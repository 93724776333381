import { captureException } from '@sentry/react';

import { WEEK_1_OFFER_IDS, WEEK_4_OFFER_IDS } from '@/entities/experiments/model/offers';

const OFFER_ID_STORAGE_KEY = 'giga_upsell_offer_id';

export function saveOfferIdLocally(offerId: string): void {
  try {
    localStorage.setItem(OFFER_ID_STORAGE_KEY, offerId);
  } catch (error) {
    captureException(new Error('Failed to save offer ID to localStorage', { cause: error }));
  }
}

export function getStoredOfferId(): string | null {
  try {
    return localStorage.getItem(OFFER_ID_STORAGE_KEY);
  } catch (error) {
    captureException(new Error('Failed to get offer ID from localStorage', { cause: error }));

    return null;
  }
}

export function clearStoredOfferId(): void {
  try {
    localStorage.removeItem(OFFER_ID_STORAGE_KEY);
  } catch (error) {
    captureException(new Error('Failed to clear offer ID from localStorage', { cause: error }));
  }
}

export function getPriceTestPaywallConfigId(offerId: string): string {
  saveOfferIdLocally(offerId);

  if (WEEK_1_OFFER_IDS.includes(offerId)) {
    return 'ai_bundle_upsell_1_week';
  }

  if (WEEK_4_OFFER_IDS.includes(offerId)) {
    return 'ai_bundle_upsell_4_week';
  }

  return 'nv_upsells_ai_bundle';
}

interface GetGigaUpsellPaywallConfigIdParams {
  testPaywallConfigId: string;
  fetchedPaywallConfigId?: string;
  isFeatureEnabled: boolean;
}

export function getGigaUpsellPaywallConfigId({
  testPaywallConfigId,
  fetchedPaywallConfigId,
  isFeatureEnabled,
}: GetGigaUpsellPaywallConfigIdParams): string {
  if (fetchedPaywallConfigId === 'nv_upsells_ai_bundle' && isFeatureEnabled) {
    return testPaywallConfigId;
  }

  return fetchedPaywallConfigId || 'giga_upsell';
}
