export const WEEK_1_OFFER_IDS = [
  '6abcd8bb-b895-4841-8fbf-5cf32175b42f', // dev
  '1e29748e-93b5-4a20-ab35-b97c30a936fe', // dev
  'a09171a7-2f5c-4ed5-8fb1-54341638ac1c', // dev
  '992b6e47-9bf1-4870-b230-cc15260ea97e', // dev
  '8edf6a08-c37f-4ce0-ae43-9db81d0eae15', // dev
  'a6830f73-94a0-41b0-91d5-de970f5bce12', // dev
  'dfa50ac0-680a-4992-84a6-d622f978e921', // dev
  '4aca30d9-cbe8-42e4-bd2b-6ac3d1889543', // dev
  '94fb88a1-38f2-42f1-892c-e9bc8955df65', // dev
  '0a4a7140-a68a-44f9-b4e0-c07868ce5dcf', // dev

  '6abcd8bb-b895-4841-8fbf-5cf32175b42f',
  '9ff570bb-4a41-45a2-93bc-7e5fd9af24e5',
  'b9d92e6c-616e-4ea7-9bd9-ae4cf96a7209',
  'c94e6ae5-673b-4242-bd09-fa159d433b08',
  'dfa50ac0-680a-4992-84a6-d622f978e921',
  '0a4a7140-a68a-44f9-b4e0-c07868ce5dcf',
  '8edf6a08-c37f-4ce0-ae43-9db81d0eae15',
  '4aca30d9-cbe8-42e4-bd2b-6ac3d1889543',
];

export const WEEK_4_OFFER_IDS = [
  '5e3f8596-5079-4867-ae52-b3043e890450', // dev
  '545519d7-50f5-417c-849a-e11a1e919dc0', // dev
  '4c886d55-115c-4aaa-b02a-c95d7a3bff28', // dev
  'e26e3071-c909-4e74-9327-9460af4b9bcc', // dev
  '98824012-cfc7-495c-83dd-6c3170b4dc76', // dev
  'f05ae86b-9ca4-43af-9d3b-d5851c9f9699', // dev
  '9014fc59-51a3-483a-ab01-80e63b9b6835', // dev
  '6ad2af5b-7a4c-47b6-a00f-3e950c2a23b5', // dev
  '82c824de-5f75-48d2-af83-124cb1c84730', // dev

  '5e3f8596-5079-4867-ae52-b3043e890450',
  'a07b811d-897a-405a-9798-99dc868d7a04',
  '564953bb-0fbe-4a7e-ba86-2bce23224c03',
  'e329e6a7-393a-4314-a452-340c79c25a93',
  'f05ae86b-9ca4-43af-9d3b-d5851c9f9699',
  '6ad2af5b-7a4c-47b6-a00f-3e950c2a23b5',
  '9014fc59-51a3-483a-ab01-80e63b9b6835',
  '82c824de-5f75-48d2-af83-124cb1c84730',
];
