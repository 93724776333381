import { useFeatureValue } from '@growthbook/growthbook-react';

export default function usePaymentHistoryExperiment() {
  const growthbookShowPaymentHistoryValue = useFeatureValue(
    'exp_payment_history',
    'default',
  );

  return growthbookShowPaymentHistoryValue;
}
