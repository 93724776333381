import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { userApi } from '@/entities/user/api';

import { userQuery } from '../../../../../context/atoms/User';

const CHALLENGES_NEXT_TASK_CONFIRMATION_FLAG =  'CHALLENGES_NEXT_TASK_CONFIRMATION_FLAG2';

export function useNextTaskConfirmationBottomSheet() {
  const user = useRecoilValue(userQuery);
  const queryClient = useQueryClient();

  const confirmationFlagQuery = useQuery({
    queryKey: [
      'challenges-next-task-confirmation',
      CHALLENGES_NEXT_TASK_CONFIRMATION_FLAG,
    ],
    queryFn: () => userApi
      .getTourPassed(user?.user_id, CHALLENGES_NEXT_TASK_CONFIRMATION_FLAG)
      .then(data => data.passed),
    staleTime: Infinity,
  });

  const confirmationFlagMutation = useMutation({
    mutationKey: [
      'challenges-next-task-confirmation',
      CHALLENGES_NEXT_TASK_CONFIRMATION_FLAG,
    ],
    mutationFn: () => userApi.postTourPassed(
      user?.user_id,
      CHALLENGES_NEXT_TASK_CONFIRMATION_FLAG,
    ),
    onSuccess() {
      return queryClient.setQueryData(
        [
          'challenges-next-task-confirmation',
          CHALLENGES_NEXT_TASK_CONFIRMATION_FLAG,
        ],
        true,
      );
    },
  });

  const isNextTaskConfirmationNeeded =    !confirmationFlagQuery.isLoading && !confirmationFlagQuery.data;

  function setNextTaskConfirmationNeeded() {
    confirmationFlagMutation.mutate();
  }

  return {
    isNextTaskConfirmationNeeded,
    setNextTaskConfirmationNeeded,
  };
}
