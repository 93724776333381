import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userApi } from '@/entities/user/api';

import { localOnboardState, userQuery } from '../../../../../context/atoms/User';
import { useOnboardingContent } from '../../../../../entities/onboarding-content/model';
import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import useAiPathwayExperiment from '../../../../../shared/lib/hooks/use-ai-pathway-experiment';
import { Loader } from '../../../../shared';
import { getAllowedArrow } from '../../../../upsell/upsell-cascade/lib';
import { OnboardingV2Analytics } from '../../../shared/analytics';
import { useLottieAiExperiment } from '../../../shared/hooks/use-lottie-ai-experiment';
import { useSearchParams } from '../../../shared/hooks/use-search-params';
import { ContentBlock } from '../../../shared/lesson-content';
import { LessonContentLayout } from '../../../shared/lesson-content/ui/LessonContentLayout';
import { LessonLayout } from '../../../shared/lesson-content/ui/LessonLayout';
import { allowedArrows } from '../../../shared/lib';
import { OnboardingV2TaskPage } from '../../../shared/task-page';

enum ProgressValues {
  START = 0,
  STEP1 = 5,
  STEP2 = 50,
  STEP3 = 60,
  STEP4 = 90,
  END = 100,
}

function getProgress(index: number): number {
  switch (index) {
    case 1:
      return ProgressValues.STEP1;
    case 2:
      return ProgressValues.STEP2;
    default:
      return ProgressValues.START;
  }
}

export const OnboardingV2IntroLessonPage = () => {
  const searchParams = useSearchParams();
  const currentLessonName = searchParams.get('lesson');
  const userArrow = searchParams.get('arrow');
  const setLocalOnboard = useSetRecoilState(localOnboardState);

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userProfileArrow =    userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];
  const userProfileArrowFiltered = getAllowedArrow(userProfileArrow, 'ai');

  const { data: onboardingContent } = useOnboardingContent(
    userProfileArrowFiltered || 'ai',
  );

  const arrow = allowedArrows.includes(userArrow as string)
    ? userArrow
    : 'main';

  const { t } = useTranslation(undefined, {
    keyPrefix: `onboarding-redesign.${arrow}`,
  });
  const lessonContent: { [key: string]: ContentBlock[] } = t('content', {
    returnObjects: true,
  });
  const user = useRecoilValue(userQuery);

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  const currentLessonKey =    currentLessonName && Object.keys(lessonContent).includes(currentLessonName)
    ? currentLessonName
    : 'lesson-1';
  const currentLesson = lessonContent[currentLessonKey || 'lesson-1'];
  const currentLessonIndex =    Object.keys(lessonContent).indexOf(currentLessonKey);
  const lessonContentLength = Object.keys(lessonContent).length;

  const [progress, setProgress] = useState(getProgress(currentLessonIndex));
  const [lastVisibleChunkIndex, setLastVisibleIndex] = useState(0);

  const filteredOnboardingContent = onboardingContent?.[
    'lesson-page'
  ]?.content.filter(chunk => chunk.type !== 'fill_the_gap');

  const taskContent = onboardingContent?.['lesson-page']?.content.filter(
    item => item.type === 'fill_the_gap',
  );

  const navigation = useHistory();

  function onFinishLesson() {
    navigation.push(`/onboarding/v2/congrats?arrow=${userArrow}`);
  }

  const lottieOnboardingExperiment = useLottieAiExperiment();

  function onClose() {
    if (!filteredOnboardingContent) {
      return;
    }

    const lastChunk = filteredOnboardingContent[lastVisibleChunkIndex];

    if (currentLessonKey.includes('lesson-')) {
      const chunkType = lastChunk.content[0].type;

      OnboardingV2Analytics.onSkipLesson({
        chunk_index:  0,
        chunk_type:   chunkType as string,
        pathway_test: aiPathwayExperiment,
        lottie_test:  lottieOnboardingExperiment,
      });
    } else {
      OnboardingV2Analytics.onSkipTask({
        pathway_test: aiPathwayExperiment,
        lottie_test:  lottieOnboardingExperiment,
      });
    }

    userApi.setOnboarded(user?.user_id as number);
    setLocalOnboard(true);
    navigation.replace('/', { from: 'onboarding' });
  }

  function onNext() {
    if (currentLessonIndex === lessonContentLength - 1) {
      onFinishLesson();

      return;
    }

    const nextLessonKey = Object.keys(lessonContent)[currentLessonIndex + 1];

    navigation.push(
      `/onboarding/v2/intro-lesson?lesson=${nextLessonKey}&arrow=${userArrow}`,
    );
  }

  if (!filteredOnboardingContent || !taskContent) {
    return <Loader />;
  }

  return (
    <LessonLayout
      progress={progress}
      onClose={onClose}
    >
      {currentLessonKey.includes('task-') ? (
        <OnboardingV2TaskPage
          key={currentLessonKey}
          taskContent={taskContent}
          moveProgress={() => {
            setProgress(prev => prev + ProgressValues.STEP2);
          }}
          progress={progress}
          onClose={onClose}
          onNext={() => {
            onNext();
          }}
        />
      ) : (
        <LessonContentLayout
          chunks={filteredOnboardingContent}
          setProgress={setProgress}
          coveredProgress={ProgressValues.STEP2}
          onNext={onNext}
          isFinish={currentLessonIndex === lessonContentLength - 1}
          lastVisibleChunkIndex={lastVisibleChunkIndex}
          setLastVisibleIndex={setLastVisibleIndex}
          arrow={userProfileArrowFiltered}
        />
      )}
    </LessonLayout>
  );
};
