import { useMemo, useState } from 'react';

import { captureException } from '@sentry/react';
import Payment, { ErrorMessage } from '@solidgate/react-sdk';
import classNames from 'classnames';
import i18next from 'i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { GeoState } from '../../../context/atoms/Geo';
import { userQuery } from '../../../context/atoms/User';
import { PAYMENT_METHODS } from '../../payment-method';
import { useSolidPaymentsForm } from '../../solid-payments-form';
import { CardFormType } from '../../solid-payments-form/type';

import { ApplePayLoader } from './ApplePayLoader';

import './apple-pay-button.css';

export function ApplePayButton({
  offerId,
  pricingId,
  paywallConfigId,
  events,
}: {
  offerId: string;
  pricingId: string;
  paywallConfigId: string;
  events: {
    onTryToPay: () => void;
    onLoaderClick: () => void;
    onSuccess: () => void;
  };
}) {
  //TODO: refactor user and geoData values
  const user = useRecoilValue(userQuery);
  const userId = user?.user_id as number;
  const userEmail = user?.email as string;
  const [geoData] = useRecoilState(GeoState);

  const isESLocale = i18next.language === 'es';
  const [isMounted, setIsMounted] = useState(false);

  const solidPaymentsFormQuery = useSolidPaymentsForm({
    offerId,
    pricingId,
    userId,
    paywallConfigId,
    countryCode: geoData?.id,
    email: userEmail,
  });

  const solidPaymentsMerchantData = useMemo(
    () =>
      solidPaymentsFormQuery.data
        ? (solidPaymentsFormQuery.data.forms.find(
            v => v.type === PAYMENT_METHODS.CARD
          )?.form as CardFormType['form'])
        : null,
    [solidPaymentsFormQuery]
  );

  function onMounted() {
    setIsMounted(true);
  }

  function onError(cause: ErrorMessage) {
    captureException(new Error('ERROR_APPLE_PAY_FORM', { cause }));
  }

  function onClick() {
    if (isMounted && solidPaymentsMerchantData) {
      events.onTryToPay();
    } else {
      events.onLoaderClick();
    }
  }

  return (
    <div
      className={classNames(
        'apple-pay-container',
        isESLocale ? 'apple-pay-container-es' : 'apple-pay-container-en'
      )}
      onClick={onClick}
    >
      {(!isMounted || !solidPaymentsMerchantData) && <ApplePayLoader />}

      {solidPaymentsMerchantData && (
        <Payment
          merchantData={{
            merchant: solidPaymentsMerchantData.merchant,
            paymentIntent: solidPaymentsMerchantData.payment_intent,
            signature: solidPaymentsMerchantData.signature,
          }}
          formParams={{
            enabled: false,
          }}
          applePayButtonParams={{
            enabled: true,
            type: 'buy',
          }}
          width="100%"
          onMounted={onMounted}
          onSuccess={events.onSuccess}
          onError={onError}
        />
      )}
    </div>
  );
}
