import { IUserPreferences } from '@/entities/user/model/types';
import http from '@/services/http.service';

export const userApi = {
  setOnboarded: async (userId: number | string) => http.post(`/users/onboard/${userId}/`, {}),
  get:          async (userId: any) => http
    .get(`/users/users/${userId}/`)
    .then(response => response.data)
    .catch(reason => null),
  changeLanguage: async (userId: any, language: string) => http
    .patch(`/users/users/${userId}/`, { language })
    .then(response => response.data)
    .catch(reason => console.log(reason)),
  getTourPassed: async (userId: any, tourName: string) => http
    .get(`users/${userId}/tours/?name=${tourName}`)
    .then(res => res.data)
    .catch(reason => console.log(reason)),
  postTourPassed: async (userId: any, tourName: string) => http
    .post(`/users/${userId}/tours/`, { name: tourName })
    .then(res => res),
  unsubscribe: async (subscriptionId: string) => http
    .post('/users/subscriptions/cancel/', {
      subscription_id: subscriptionId,
    })
    .then(response => response.data)
    .catch(reason => console.log(reason)),
  restoreSubscription: async (subscriptionId: string) => http
    .post('/users/subscriptions/restore/', {
      subscription_id: subscriptionId,
    })
    .then(response => response.data)
    .catch(reason => console.log(reason)),
  extendSubscription: async (userId: any, subscriptionId: string) => http
    .post('/users/subscriptions/extend/', {
      user_id: userId,
      subscription_id: subscriptionId,
    })
    .then(response => response.data)
    .catch(reason => console.log(reason)),
  getUserPreferences: async (userId: number) => http
    .get(`/users/${userId}/preferences/`)
    .then(response => response.data)
    .catch(reason => console.log(reason)),
  setUserPreferences: async (userId: number, data: Partial<IUserPreferences>) => http
    .post(`/users/${userId}/preferences/`, data)
    .then(response => response.data)
    .catch(reason => console.log(reason)),
};