import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn:              process.env.REACT_APP_SENTRY_DSN,
  integrations:     [new BrowserTracing()],
  tracesSampleRate: 0.5,
  environment:      process.env.NODE_ENV,
  sampleRate:       0.2,
});
