import {
  DefaultValue,
  atom,
  selector,
  selectorFamily,
} from 'recoil';

import { userApi } from '@/entities/user/api';

import { usersApi } from '../../api/usersApi';
import cognito from '../../services/cognito.service';
import { IHasAccess } from '../../types/Profile';
import {
  ILongUser,
  IResetUser,
  IUser,
  IUserData,
} from '../../types/User';

export const userTrigger = atom<any>({
  key:     'userTrigger',
  default: 0,
});

export const userQuery = selector<IUser | null>({
  key: 'userQuery',
  get: async ({ get }) => {
    get(userTrigger);

    return await cognito.getUser();
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(userTrigger, (v: number) => v + 1);
    }
  },
});

export const userAccessQuery = selectorFamily({
  key: 'userAccess',
  get:
    user_id => async ({ get }) => {
      if (!user_id) {
        return {} as IHasAccess;
      }

      return await usersApi.checkAccess(user_id);
    },
});

export const paymentMethodQuery = selectorFamily({
  key: 'paymentMethod',
  get: user_id => async () => {
    if (!user_id) {
      return null;
    }

    return await usersApi.getPaymentMethod(user_id);
  },
});

export const userDataQuery = selector<IUserData | null>({
  key: '_userData',
  get: async ({ get }) => {
    const user = get(userQuery);

    if (!user) {
      return null;
    }

    return await userApi.get(user.user_id);
  },
});

export const localOnboardState = atom<any>({
  key:     'localOnboard',
  default: false,
});

export const localGraceSubscriptionState = atom<boolean>({
  key:     'localGraceSubscription',
  default: false,
});

export const LongUserState = atom<ILongUser>({
  key:     'userState',
  default: {} as ILongUser,
});

export const IUserState = atom<IResetUser>({
  key:     'IUserState',
  default: {} as IResetUser,
});
