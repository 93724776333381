import { useFeatureValue } from '@growthbook/growthbook-react';
import { isEmpty } from 'lodash-es';

import { useUserPreferences, useSetUserPreferences } from '@/entities/user/lib/hooks/useUserPreferences';
import { OnboardingPages, VisitedPagesType } from '@/entities/user/model/types';
import { isAndroid, isIOS16OrLater } from '@/shared/lib';

export const useOnboardingPage = (userId: number = 0) => {
  const { mutate: postUserPreferences, isLoading: isUpdateUserPreferences } = useSetUserPreferences();
  const { data: userPreferences } = useUserPreferences(userId);
  const gigaUpsellName = useFeatureValue('giga_upsell_feature', 'default');
  const megaUpsellName = useFeatureValue('mega_upsell_feature', 'upsell_bundle_new_design_test');

  const visitedPages = userPreferences?.visited_pages;

  const sendVisitPage = async (page: OnboardingPages, isLocal = false) => {
    if (visitedPages && visitedPages[page]) return Promise.reject(new Error('Page already visited'));

    if (isLocal) return Promise.resolve();

    return postUserPreferences({
      userId,
      data: {
        visited_pages: {
          ...visitedPages,
          [page]: true,
        },
      },
    });
  };

  const getOnboardingRoute = (pages?: VisitedPagesType): string => {
    if (isEmpty(pages)) return '';

    if (pages[OnboardingPages.GIGA_UPSELL] === false) {
      return `/upsell-single-giga/${gigaUpsellName}`;
    }

    if (pages[OnboardingPages.MEGA_UPSELL] === false) {
      return `/upsell-mega/${megaUpsellName}`;
    }

    if (pages[OnboardingPages.WEB_TO_APP] === false && (isAndroid() || isIOS16OrLater())) {
      return '/web-to-app';
    }

    return '/onboarding/v2';
  };

  const initVisitedPages = (userId: number, isNeedWebToApp = false) => {
    if (isUpdateUserPreferences) return Promise.reject(new Error('Request is already in progress'));

    const initialVisitedPages = {
      [OnboardingPages.MEGA_UPSELL]: false,
      [OnboardingPages.GIGA_UPSELL]: false,
      ...(isNeedWebToApp && { [OnboardingPages.WEB_TO_APP]: false }),
      [OnboardingPages.ONBOARDING]:  false,
    };

    return postUserPreferences({
      userId,
      data: {
        visited_pages: initialVisitedPages,
      },
    });
  };

  return {
    getOnboardingRoute,
    isUpdateUserPreferences,
    visitedPages,
    initVisitedPages,
    postUserPreferences,
    sendVisitPage,
  };
};
