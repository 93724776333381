import { IAuthUser, ILongUser } from '@/types/User';

import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useGrowthBook } from '@growthbook/growthbook-react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import { captureException } from '@sentry/react';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useUserActions } from '@/context/actions/user.actions';
import { languageState } from '@/context/atoms/Language';
import { userQuery } from '@/context/atoms/User';
import { AuthStore } from '@/entities/auth';
import { clearTempUser } from '@/entities/temp-user/model/storage';
import PrivacyCheckbox from '@/modules/Auth/Signup/PrivacyCheckbox/PrivacyCheckbox';
import { WarningModal } from '@/modules/Auth/Signup/WarningModal';
import { Loader } from '@/modules/shared';
import { analyticsService } from '@/services/analytics.service';
import cognitoService from '@/services/cognito.service';
import { checkIsEmailValid } from '@/utils/Auth/Auth';
import { getExistedAppLanguage, setAppLanguage } from '@/utils/Language/Language';
import { getQueryParamsFromURI } from '@/utils/QueryParams/QueryParams';

import VerificationModal from '../VerificationModal/VerificationModal';

import {
  Heading,
  NotStickyButton,
  Subtitle,
} from './CreateAccountComponent.styles';

export const CreateAccountComponent = ({
  assistantTest,
  selectedArrow,
  events,
}: {
  assistantTest: string;
  selectedArrow: string;
  events: {
    onGoToNextPage: (isAssistantTest?: string) => void;
  };
}) => {
  const resetUser = useResetRecoilState(userQuery);
  const userActions = useUserActions();

  const [longUser, setLongUser] = useState(userActions.getLongUser());
  const [isRedirected, setIsRedirected] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] =    useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [verificationEmail, setVerificationEmail] = useState(longUser.email);
  const [emailValidation, setEmailValidation] = useState({});

  const [username, setUserName] = useState('');
  const [usernameValidation, setUsernameValidation] = useState({});
  const [usernameChecked, setUsernameChecked] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState({});
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isUidReady, setIsUidReady] = useState(false);
  const [_, setLanguageCode] = useRecoilState(languageState);
  const [passwordConfirmValidation, setPasswordConfirmValidation] = useState(
    {},
  );
  const [formValid, setFormValid] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isShowCheckError, setIsShowCheckError] = useState(false);

  const emailRef = useRef() as MutableRefObject<HTMLInputElement>;
  const queryParams = getQueryParamsFromURI();
  const { t } = useTranslation();

  const growthbook = useGrowthBook();

  function handleVerificationModalChange(email: string) {
    setVerificationEmail(email);
    setEmailValidation({});
  }

  function handleRedirect() {
    setIsLoader(false);

    AuthStore.setEmail(verificationEmail);
    AuthStore.setPassword(password);

    events.onGoToNextPage(assistantTest);
  }

  function localSignIn(userId: number) {
    cognitoService.cleanUpCognitoCookies();

    cognitoService
      .signIn(verificationEmail, password)
      .then(() => {
        cognitoService.getSession().then(() => {
          setShowEmailVerificationModal(false);

          userActions
            .sendAction(userId.toString(), 'registered')
            .then(() => {
              resetUser();
              clearTempUser();
            })
            .then(() => analyticsService.setUserId(userId.toString()));

          setIsRedirected(true);
          localStorage.setItem('userId', `${userId}`);

          setIsSignedIn(true);
          handleRedirect();
        });
      })
      .catch((cause) => {
        throw new Error('ERROR_SIGNIN', { cause });
      });
  }

  async function registerUser() {
    let userData = {} as IAuthUser;
    let prosperiUserData = longUser;

    if (longUser.id) {
      userData = {
        email:   verificationEmail,
        password,
        code:    '',
        username,
        user_id: longUser.id.toString(),
      };
    } else {
      const fetchedUser = await userActions
        .createUser(verificationEmail, {})
        .catch((err) => {
          if (err.response.data.email) {
            setEmailValidation({
              error:      true,
              helperText: err.response.data.email,
            });
          }

          // ? wtf?
          return {} as ILongUser;
        });

      if (Object.keys(fetchedUser).length === 0) {
        return;
      }

      prosperiUserData = fetchedUser;
      setLongUser(fetchedUser);

      userData = {
        email:   verificationEmail,
        password,
        code:    '',
        username,
        user_id: fetchedUser.id.toString(),
      };
    }

    setIsLoader(true);

    cognitoService
      .signUp(userData)
      .then((res) => {
        userActions
          .saveUser(prosperiUserData.email, username, verificationEmail)
          .then(() => {
            userActions.updateEmail(prosperiUserData.id, verificationEmail);
          });

        analyticsService.sendEvent(
          'pr_funnel_registration_create_account_click',
          { success: true },
        );

        return growthbook?.setAttributes({
          id:    prosperiUserData.id.toString(),
          arrow: selectedArrow,
        });
      })
      .then(() => {
        localSignIn(prosperiUserData.id);
      })
      .catch((error) => {
        setIsLoader(false);
        captureException(new Error('ERROR_SIGNUP', { cause: error }));
        analyticsService.sendEvent(
          'pr_funnel_registration_create_account_click',
          {
            success:    false,
            error_code: error.code,
          },
        );

        switch (error.code) {
          case 'UsernameExistsException':
            setShowWarningModal(true);
            break;
          case 'NetworkError':
            setShowEmailVerificationModal(false);
            break;
          case 'InvalidParameterException':
            setShowEmailVerificationModal(false);
            break;
          case 'InvalidPasswordException':
            setShowEmailVerificationModal(false);
            setPasswordValidation({
              error:      true,
              helperText: t('Please enter a valid password'),
            });
            break;
          default:
            break;
        }
      })
      .finally(() => {
        setShowEmailVerificationModal(false);
      });
  }

  function closeWarningModal() {
    setShowWarningModal(false);
  }

  function validateEmail() {
    if (!verificationEmail) return;

    if (checkIsEmailValid(verificationEmail)) {
      setEmailValidation({});

      return;
    }

    setEmailValidation({
      error:      true,
      helperText: t('Enter a valid email address'),
    });
  }

  function validateUsername() {
    if (username.replace(/\s/g, '').length === 0) {
      setUsernameValidation({
        error:      true,
        helperText: t('Please enter a valid username'),
      });

      return;
    }

    // check if username exists
    if (username) {
      userActions
        .checkUsername(username)
        .then((res: any) => {
          if (res.data.error) {
            setUsernameValidation({
              error:      true,
              helperText: res.data.error,
            });
            setUsernameChecked(true);
          } else {
            setUsernameValidation({});
            setUsernameChecked(true);
          }
        })
        .catch(() => {
          setUsernameValidation({
            error:      true,
            helperText: 'Please choose another username',
          });
          setUsernameChecked(true);
        });
    }
  }

  function validatePassword() {
    if (password) {
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumbers = /\d/.test(password);
      const isLongEnough = password.length > 5;
      const re_whitespace = /\s/;

      if (re_whitespace.test(password)) {
        setPasswordValidation({
          error:      true,
          helperText: 'Your password cannot contain whitespace characters',
        });

        return;
      }

      if (!hasLowerCase || !hasNumbers || !isLongEnough) {
        setPasswordValidation({
          error: true,
          helperText:
            'Your password should be at least 6 characters and contain a number and a letter',
        });

        return;
      }
    }

    if (passwordConfirm) {
      validatePasswordConfirm(passwordConfirm);

      return;
    }

    if (password && passwordConfirm && password === passwordConfirm) {
      setPasswordConfirmValidation({});
    }
  }

  function handleFormSubmit() {
    if (!isChecked) {
      setIsShowCheckError(true);

      return;
    }

    setShowEmailVerificationModal(true);
  }

  function validatePasswordConfirm(confirmPassword: string) {
    setPasswordConfirmValidation({});

    if (Object.prototype.hasOwnProperty.call(passwordValidation, 'error')) {
      return;
    }

    const re_whitespace = /\s/;

    if (re_whitespace.test(confirmPassword)) {
      setPasswordConfirmValidation({
        error:      true,
        helperText: 'Your password cannot contain whitespace characters',
      });

      return;
    }

    if (password && confirmPassword && password !== confirmPassword) {
      setPasswordConfirmValidation({
        error:      true,
        helperText: t('Passwords do not match'),
      });

      return;
    }

    if (password && confirmPassword && password === confirmPassword) {
      setPasswordConfirmValidation({});
    }
  }

  function toggleCheck() {
    if (!isChecked) {
      setIsShowCheckError(false);
    }

    setIsChecked(prev => !prev);
  }

  useEffect(() => {
    const emailValid = verificationEmail && !Object.prototype.hasOwnProperty.call(emailValidation, 'error');

    const usernameValid = username
      && usernameChecked
      && !Object.prototype.hasOwnProperty.call(usernameValidation, 'error');

    const passwordValid = password && !Object.prototype.hasOwnProperty.call(passwordValidation, 'error');

    const passwordConfirmValid = passwordConfirm
      && !Object.prototype.hasOwnProperty.call(passwordConfirmValidation, 'error')
      && password === passwordConfirm;

    if (emailValid && usernameValid && passwordValid && passwordConfirmValid) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [
    emailValidation,
    usernameValidation,
    passwordValidation,
    passwordConfirmValidation,
    usernameChecked,
  ]);

  useEffect(() => {
    if (isUidReady) {
      analyticsService.sendEvent(
        'pr_funnel_registration_create_account_page_view',
        {
          // from email marketing
          email_catch: !!queryParams.email_chase,
        },
      );
    }
  }, [isUidReady]);

  //! CREATE USER FROM QUERY PARAMS
  useEffect(() => {
    // start create user by query params
    if (queryParams.email) {
      if (queryParams.uid) {
        const uid = queryParams.uid;

        // Maybe uniq
        analyticsService.setUserId(uid);

        setIsUidReady(true);

        growthbook?.setAttributes({
          id:    uid.toString(),
          arrow: selectedArrow,
        });
      }

      if (queryParams.lang) {
        setAppLanguage(
          getExistedAppLanguage(queryParams.lang),
          Number(queryParams.uid),
          false,
        );
        setLanguageCode(getExistedAppLanguage(queryParams.lang));
      }

      const filtered_email = queryParams.email;

      userActions
        .createUser(filtered_email, {}, queryParams.uid || '')
        .then((res) => {
          const queriedUser = res;

          emailRef.current.value = queriedUser.email;
          setLongUser(queriedUser);
          setVerificationEmail(queriedUser.email);

          // Check user
          if (queriedUser.verification_email) {
            setShowWarningModal(true);
            // check this
          }
        })
        .catch((err) => {
          if (
            get(err, 'response.data.detail', '')
              .toLowerCase()
              .includes('email is already registered')
          ) {
            setShowWarningModal(true);
          }
          // handle errors
        });
      // end create user by query params
    } else {
      setVerificationEmail(longUser.email);

      if (longUser.email) {
        emailRef.current.value = longUser.email;
      }

      setLongUser(userActions.getLongUser());
      setIsUidReady(true);
    }
  }, [queryParams.email]);

  // ? REDIRECT FOR WHAT?
  useEffect(() => {
    if (isRedirected || !isSignedIn) return;

    handleRedirect();
  }, [isRedirected, isSignedIn]);

  if (isLoader) {
    return <Loader />;
  }

  return (
    <>
      <Heading>{t('Create your account')}</Heading>
      <Subtitle>{t('To save your progress and access the plan')}</Subtitle>

      <form>
        <TextField
          {...emailValidation}
          inputRef={emailRef}
          sx={{ width: 1 }}
          id="email"
          type="text"
          label={t('Email')}
          name="email"
          autoComplete="on"
          margin="normal"
          onChange={(e) => {
            setVerificationEmail(e.target.value.trim());
            setEmailValidation({});
          }}
          onBlur={() => validateEmail()}
          placeholder={t('example@gmail.com')!}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          {...usernameValidation}
          sx={{ width: 1 }}
          id="username"
          type="text"
          label={t('Username')}
          name="username"
          margin="normal"
          placeholder={t('Create a username')!}
          onChange={(e) => {
            setUserName(e.target.value);
            setUsernameValidation({});
            setUsernameChecked(false);
          }}
          onBlur={() => validateUsername()}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          {...passwordValidation}
          sx={{ width: 1 }}
          id="password"
          type={showPassword ? 'text' : 'password'}
          label={t('Password')}
          name="password"
          margin="normal"
          placeholder={t('Enter your password')!}
          autoComplete="new-password"
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordValidation({});
            setPasswordConfirmValidation({});
          }}
          onBlur={() => validatePassword()}
          InputProps={{
            endAdornment: (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
                style={{ all: 'unset' }}
              >
                <VisibilityIcon style={{ color: '#6c757d' }} />
              </button>
            ),
          }}
        />

        <TextField
          {...passwordConfirmValidation}
          sx={{ width: 1 }}
          id="confirmPassword"
          type={showPassword ? 'text' : 'password'}
          label={t('Confirm Password')}
          name="confirmPassword"
          onChange={(e) => {
            setPasswordConfirm(e.target.value);
            validatePasswordConfirm(e.target.value);
          }}
          margin="normal"
          placeholder={t('Confirm your password')!}
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
                style={{ all: 'unset' }}
              >
                <VisibilityIcon style={{ color: '#6c757d' }} />
              </button>
            ),
          }}
        />
      </form>

      <PrivacyCheckbox
        isChecked={isChecked}
        toggleCheck={toggleCheck}
        isShowError={isShowCheckError}
      />

      <NotStickyButton>
        <button
          type="button"
          className={formValid ? '' : 'disabled'}
          onClick={handleFormSubmit}
          disabled={!formValid}
        >
          {t('Create account')}
        </button>
      </NotStickyButton>

      <VerificationModal
        modalState={showEmailVerificationModal}
        onchange={handleVerificationModalChange}
        emailValidation={emailValidation}
        validate={validateEmail}
        email={verificationEmail}
        closeAction={() => {
          setShowEmailVerificationModal(false);
        }}
        submitAction={registerUser}
      />

      <WarningModal
        warningModalState={showWarningModal}
        closeWarningModal={closeWarningModal}
      />
    </>
  );
};
