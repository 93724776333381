import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { userApi } from '@/entities/user/api';

import { IUserData } from '../../../types/User';

const QUERY_KEY = 'satisfaction-survey';

export function useSatisfactionSurveyTourQuery(userData: IUserData | null) {
  const queryClient = useQueryClient();
  const growthbook = useGrowthBook();
  const growthbookSatisfactionSurveyTourValue = useFeatureValue(
    'satisfaction_survey_tour_value',
    'satisfaction_survey_tour_value',
  );

  const satisfactionSurveyTourFlagQuery = useQuery({
    queryKey: [QUERY_KEY, growthbookSatisfactionSurveyTourValue],
    queryFn:  () => userApi
      .getTourPassed(userData?.id, growthbookSatisfactionSurveyTourValue)
      .then(data => data.passed),
    staleTime: Infinity,
    enabled:   Boolean(growthbook?.ready && userData?.id),
  });

  const satisfactionSurveyTourFlagMutation = useMutation({
    mutationKey: [QUERY_KEY, growthbookSatisfactionSurveyTourValue],
    mutationFn:  () => userApi.postTourPassed(
      userData?.id,
      growthbookSatisfactionSurveyTourValue,
    ),
    onSuccess() {
      return queryClient.setQueryData(
        [QUERY_KEY, growthbookSatisfactionSurveyTourValue],
        true,
      );
    },
  });

  const isSatisfactionSurveyTourVisible =    !satisfactionSurveyTourFlagQuery.isLoading
    && !satisfactionSurveyTourFlagQuery.data;

  function setSatisfactionSurveyTourVisible() {
    if (isSatisfactionSurveyTourVisible) {
      satisfactionSurveyTourFlagMutation.mutate();
    }
  }

  return {
    isSatisfactionSurveyTourVisible,
    setSatisfactionSurveyTourVisible,
  };
}
