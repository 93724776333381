import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

/**
 * @description
 * upsell_notion_report is default single upsell,
 * sometimes offer_single doesn't come and we use default
 * */

type SingleOfferPageView = {
  offerId: string;
  pricingId: string;
  price: number;
  currency: string;
  place: 'signup_singleoffer_upsell' | 'signup_gigaupsell';
  paymentMethod: string;
  is_oneclick: boolean;
  paywall_config_id: string;
  upsellName?: string;
  experiment1Week?: string;
  experiment4Week?: string;
};

type MultipleOfferPageView = {
  place: 'signup_multioffer_upsell';
  paymentMethod: string;
  paywall_config_id: string;
  is_oneclick: boolean;
  upsellName?: string;
};

export const UpsellAnalytics = {
  legacyPageView({
    price,
    currency,
    isDiscountUpsell,
    isOneClick,
    upsellName,
    experiment1Week,
    experiment4Week,
  }: {
    price: number;
    currency: string;
    isDiscountUpsell: boolean;
    isOneClick: boolean;
    upsellName?: string;
    experiment1Week?: string;
    experiment4Week?: string;
  }) {
    analyticsService.sendEvent('pr_funnel_upsell_view', {
      type:        'gptbible_with_value',
      chase:       isDiscountUpsell ? 'yes' : 'no',
      pricing:     'one-time',
      price,
      currency,
      is_oneclick: isOneClick,
      name:        upsellName,
      ...(experiment1Week && { exp_upsell_pricing_1_week_v1: experiment1Week }),
      ...(experiment4Week && { exp_upsell_pricing_4_week_v1: experiment4Week }),
    });
  },
  pageView(props: MultipleOfferPageView | SingleOfferPageView) {
    if (props.place === 'signup_multioffer_upsell') {
      analyticsService.sendEvent('webapp_upsell_view', {
        place:             props.place,
        feature:           'signup',
        paywall_config_id: props.paywall_config_id,
        language:          i18next.language,
        payment_method:    props.paymentMethod,
        is_oneclick:       props.is_oneclick,
        name:              props.upsellName,
      });
    } else {
      analyticsService.sendEvent('webapp_upsell_view', {
        place:             props.place,
        offer_id:          props.offerId,
        pricing_id:        props.pricingId,
        price:             props.price,
        currency:          props.currency,
        feature:           'signup',
        paywall_config_id: props.paywall_config_id,
        language:          i18next.language,
        payment_method:    props.paymentMethod,
        is_oneclick:       props.is_oneclick,
        name:              props.upsellName,
        ...(props.experiment1Week && { exp_upsell_pricing_1_week_v1: props.experiment1Week }),
        ...(props.experiment4Week && { exp_upsell_pricing_4_week_v1: props.experiment4Week }),
      });
    }
  },
  /**
   * @description
   * Send event, when skip upsell-cascade page
   * */
  pageViewWithError({
    place,
    paymentMethod,
    paywallConfigId,
    isOneClick,
  }: {
    place:
      | 'signup_multioffer_upsell'
      | 'signup_singleoffer_upsell'
      | 'signup_gigaupsell';
    paywallConfigId: string;
    paymentMethod: string;
    isOneClick: boolean;
  }) {
    analyticsService.sendEvent('webapp_upsell_view', {
      place,
      paywall_config_id: paywallConfigId,
      feature:           'signup',
      action:            'error',
      language:          i18next.language,
      payment_method:    paymentMethod,
      is_oneclick:       isOneClick,
    });
  },
  onChooseOffer({
    offerId,
    pricingId,
    price,
    currency,
    paymentMethod,
    paywallConfigId,
    isOneClick,
  }: {
    offerId: string;
    pricingId: string;
    price: number;
    currency: string;
    paywallConfigId: string;
    paymentMethod: string;
    isOneClick: boolean;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place:             'signup_multioffer_upsell',
      feature:           'signup',
      action:            'select_offer',
      paywall_config_id: paywallConfigId,
      offer_id:          offerId,
      pricing_id:        pricingId,
      price,
      currency,
      language:          i18next.language,
      payment_method:    paymentMethod,
      is_oneclick:       isOneClick,
    });
  },
  onTryToPay({
    place,
    offerId,
    pricingId,
    price,
    currency,
    paymentMethod,
    paywallConfigId,
    isOneClick,
    upsellName,
    experiment1Week,
    experiment4Week,
  }: {
    place:
      | 'signup_multioffer_upsell'
      | 'signup_singleoffer_upsell'
      | 'signup_gigaupsell';
    offerId: string;
    pricingId: string;
    price: number;
    currency: string;
    paymentMethod: string;
    paywallConfigId: string;
    isOneClick: boolean;
    upsellName?: string;
    experiment1Week?: string;
    experiment4Week?: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place,
      feature:           'signup',
      action:            'try2pay',
      paywall_config_id: paywallConfigId,
      offer_id:          offerId,
      pricing_id:        pricingId,
      price,
      currency,
      language:          i18next.language,
      payment_method:    paymentMethod,
      is_oneclick:       isOneClick,
      name:              upsellName,
      ...(experiment1Week && { exp_upsell_pricing_1_week_v1: experiment1Week }),
      ...(experiment4Week && { exp_upsell_pricing_4_week_v1: experiment4Week }),
    });
  },
  onApplePayLoaderClick({
    place,
    offerId,
    pricingId,
    price,
    currency,
    paywallConfigId,
    isOneClick,
    upsellName,
    experiment1Week,
    experiment4Week,
  }: {
    place:
      | 'signup_multioffer_upsell'
      | 'signup_singleoffer_upsell'
      | 'signup_gigaupsell';
    offerId: string;
    pricingId: string;
    price: number;
    currency: string;
    paywallConfigId: string;
    isOneClick: boolean;
    upsellName?: string;
    experiment1Week?: string;
    experiment4Week?: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place,
      feature:           'signup',
      action:            'apple_pay_loader',
      paywall_config_id: paywallConfigId,
      offer_id:          offerId,
      pricing_id:        pricingId,
      price,
      currency,
      language:          i18next.language,
      payment_method:    'APPLEPAY',
      is_oneclick:       isOneClick,
      name:              upsellName,
      ...(experiment1Week && { exp_upsell_pricing_1_week_v1: experiment1Week }),
      ...(experiment4Week && { exp_upsell_pricing_4_week_v1: experiment4Week }),
    });
  },
  onSkip({
    position,
    place,
    paymentMethod,
    paywallConfigId,
    isOneClick,
    upsellName,
    experiment1Week,
    experiment4Week,
  }: {
    position: 'top' | 'bottom';
    paymentMethod: string;
    place:
      | 'signup_multioffer_upsell'
      | 'signup_singleoffer_upsell'
      | 'signup_gigaupsell';
    paywallConfigId: string;
    isOneClick: boolean;
    upsellName?: string;
    experiment1Week?: string;
    experiment4Week?: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place,
      paywall_config_id: paywallConfigId,
      feature:           'signup',
      action:            'skip',
      position,
      language:          i18next.language,
      payment_method:    paymentMethod,
      is_oneclick:       isOneClick,
      name:              upsellName,
      ...(experiment1Week && { exp_upsell_pricing_1_week_v1: experiment1Week }),
      ...(experiment4Week && { exp_upsell_pricing_4_week_v1: experiment4Week }),
    });
  },
  onAllUpsellSkip({
    isOneClick,
    place,
    experiment1Week,
    experiment4Week,
  }: {
    isOneClick: boolean;
    place: 'signup_gigaupsell' | 'signup_multioffer_upsell' | 'signup_singleoffer_upsell';
    experiment1Week?: string;
    experiment4Week?: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place,
      feature:     'signup',
      action:      'unavailable_apple_pay',
      language:    i18next.language,
      is_oneclick: isOneClick,
      ...(experiment1Week && { exp_upsell_pricing_1_week_v1: experiment1Week }),
      ...(experiment4Week && { exp_upsell_pricing_4_week_v1: experiment4Week }),
    });
  },
};
