import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { motion } from 'framer-motion';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userApi } from '@/entities/user/api';

import { localOnboardState, userQuery } from '../../../../../context/atoms/User';
import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { OnboardingAnalytics } from '../../../shared/analytics';
import {
  QUIZ_SCREEN_FORMAT,
  QUIZ_SCREEN_GOAL,
  QUIZ_SCREEN_INTEREST,
  QUIZ_SCREEN_LEVEL,
  QUIZ_SCREEN_SKILLS,
} from '../../../shared/config';

import './assets/quiz-page.css';
import { QuizFormatScreen } from './screens/format/QuizFormatScreen';
import { QuizGoalScreen } from './screens/goal/QuizGoalScreen';
import { QuizInterestScreen } from './screens/interest/QuizInterestScreen';
import { QuizLevelScreen } from './screens/level/QuizLevelScreen';
import { QuizSkillsScreen } from './screens/skills/QuizSkillsScreen';

const STEPS = [
  QUIZ_SCREEN_INTEREST,
  QUIZ_SCREEN_SKILLS,
  QUIZ_SCREEN_FORMAT,
  QUIZ_SCREEN_LEVEL,
  QUIZ_SCREEN_GOAL,
] as const;

const PROGRESS_STEP = 100 / STEPS.length;

export const OnboardingQuizPage = () => {
  const [animatedRef] = useAutoAnimate();
  const user = useRecoilValue(userQuery);
  const setLocalOnboard = useSetRecoilState(localOnboardState);
  const navigation = useHistory();

  const [process, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const currentScreen = useMemo(() => STEPS[currentStep], [currentStep]);
  const growthbookAbOnboardingQuiz = useFeatureValue(
    'ab_onboarding_quiz',
    'default',
  );

  // @description - prefetch
  usePersonalProfileQuery();

  useEffect(() => {
    OnboardingAnalytics.onQuizPageView({
      question:              currentScreen,
      abOnboardingQuizValue: growthbookAbOnboardingQuiz,
    });
  }, [currentScreen]);

  function goToNextStep() {
    setProgress(v => v + PROGRESS_STEP);

    if (currentScreen === QUIZ_SCREEN_GOAL) {
      userApi.setOnboarded(user?.user_id as number);
      setLocalOnboard(true);
      navigation.push('/', { from: 'onboarding' });
    } else {
      setCurrentStep(v => v + 1);
    }
  }

  function onBackButtonClick() {
    setProgress(v => v - PROGRESS_STEP);

    if (currentScreen === QUIZ_SCREEN_INTEREST) {
      navigation.replace('/onboarding/info');
    } else {
      setCurrentStep(v => v - 1);
    }
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="quiz-page min-vh-full"
    >
      <div className="quiz-page__header">
        <button
          type="button"
          className="quiz-page__back-button"
          onClick={onBackButtonClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#6B7280"
              d="M6.48 12.164c0-.13.022-.25.065-.362a.954.954 0 0 1 .232-.325l6.902-6.745a.855.855 0 0 1 .64-.269c.173 0 .328.04.464.121.143.08.254.189.334.325.087.136.13.293.13.473a.89.89 0 0 1-.278.65l-6.272 6.132 6.272 6.132a.89.89 0 0 1 .278.65.846.846 0 0 1-.13.463.867.867 0 0 1-.334.334.895.895 0 0 1-.464.12.855.855 0 0 1-.64-.268L6.777 12.85a.988.988 0 0 1-.232-.315 1.065 1.065 0 0 1-.065-.371Z"
            />
          </svg>
        </button>

        <div className="quiz-page__progress">
          <div
            className="quiz-page__progress-line"
            style={{ width: `${process}%` }}
          />
        </div>
      </div>

      <div
        ref={animatedRef}
        className="quiz-page__content"
      >
        {currentScreen === QUIZ_SCREEN_INTEREST && (
          <QuizInterestScreen events={{ onNext: goToNextStep }} />
        )}
        {currentScreen === QUIZ_SCREEN_SKILLS && (
          <QuizSkillsScreen events={{ onNext: goToNextStep }} />
        )}
        {currentScreen === QUIZ_SCREEN_FORMAT && (
          <QuizFormatScreen events={{ onNext: goToNextStep }} />
        )}
        {currentScreen === QUIZ_SCREEN_LEVEL && (
          <QuizLevelScreen events={{ onNext: goToNextStep }} />
        )}
        {currentScreen === QUIZ_SCREEN_GOAL && (
          <QuizGoalScreen events={{ onNext: goToNextStep }} />
        )}
      </div>
    </motion.section>
  );
};
