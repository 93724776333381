export enum OnboardingPages {
  GIGA_UPSELL = 'gigaUpsell',
  MEGA_UPSELL = 'megaUpsell',
  WEB_TO_APP = 'webToApp',
  ONBOARDING = 'onboarding',
}

export type VisitedPagesType = {
  [key in OnboardingPages]?: boolean;
};

export interface IUserPreferences {
  experiments?: Record<string, string| number| boolean>;
  visited_pages?: VisitedPagesType;
  streaks?: string;
}
