import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { useWebToAppAndroidExperiment } from '@/modules/upsell/shared/hooks/use-web-to-app-android-experiment';
import { isAndroid, isIOS16OrLater } from '@/shared/lib';

interface IUseWebToAppEnabled {
  isEnabled: boolean;
}

export const useWebToAppEnabled = (): IUseWebToAppEnabled => {
  const isWebToAppScreenShow = useFeatureIsOn('ios_web2app_screen_show');
  const androidWebToAppExperiment = useWebToAppAndroidExperiment();

  return {
    isEnabled:
    (isIOS16OrLater() && isWebToAppScreenShow)
    || (isAndroid() && androidWebToAppExperiment === 'test'),
  };
};
