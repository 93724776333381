import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import { captureException } from '@sentry/react';
import { useRecoilValue } from 'recoil';

import { useABTestGigaUpsellPricing, getGigaUpsellPaywallConfigId } from '@/entities/experiments';
import { analyticsService } from '@/services/analytics.service';
import { getOsWithVersion } from '@/shared/lib';

import { userQuery } from '../../../context/atoms/User';
import useAssistantUpsellExperiment from '../../../hooks/use-assistant-upsell-experiment';
import { usePrefetchCurrencySignQuery } from '../../../hooks/use-currency-sign-query';
import i18n from '../../../i18n';
import { getQueryParamsFromURI } from '../../../utils/QueryParams/QueryParams';
import LanguageNavbar from '../../shared/Navbar/LanguageNavbar';
import { useUpsellCascadePrefetch, useUpsellSinglePrefetch } from '../../upsell';
import { useGigaUpsellContent } from '../../upsell/upsell-single/entities/giga-upsell-content/model';

import { CreateAccountComponent } from './CreateAccountComponent/CreateAccountComponent';
import { getArrowByPrcId } from './lib/utils';
import ProgressComponent from './ProgressBar/ProgressBar';
import { progressSteps } from './ProgressBarData';
import {
  ContainerWithReviews,
  PositionedPageContainer,
  Wrapper,
} from './Signup.styles';

export const SignUpPage = () => {
  const navigation = useHistory();
  const user = useRecoilValue(userQuery);
  const queryParams = getQueryParamsFromURI();

  const selectedArrow = getArrowByPrcId(queryParams.prc_id);
  const offerId = queryParams.offer_id;

  const { value: assistantUpsellTestValue } = useAssistantUpsellExperiment({
    arrow: selectedArrow,
  });

  const upsellName = useFeatureValue('giga_upsell_feature', 'default');

  const growthbook = useGrowthBook();

  const { isFeatureEnabled, paywallConfigId: testPaywallConfigId } = useABTestGigaUpsellPricing({
    offerId,
  });

  const { prefetchUpsellCascadeQuery } = useUpsellCascadePrefetch();
  const { prefetchUpsellSingleQuery } = useUpsellSinglePrefetch();
  const { prefetchCurrencyQuery } = usePrefetchCurrencySignQuery();
  const { data: upsellData } = useGigaUpsellContent(
    upsellName || 'default',
    i18n.language,
  );

  useEffect(() => {
    if (user?.user_id) {
      growthbook?.setAttributes({
        id: user.user_id.toString(),
      }).catch((error) => {
        captureException(new Error('Failed to set growthbook attributes', { cause: error }));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    prefetchUpsellCascadeQuery().catch((error) => {
      captureException(new Error('Failed to prefetch upsell cascade', { cause: error }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    prefetchCurrencyQuery().catch((error) => {
      captureException(new Error('Failed to prefetch currency', { cause: error }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const actualPaywallConfigId = getGigaUpsellPaywallConfigId({
      testPaywallConfigId,
      fetchedPaywallConfigId: upsellData?.paywallConfigId,
      isFeatureEnabled,
    });

    prefetchUpsellSingleQuery({
      paywallConfigId: actualPaywallConfigId,
    }).catch((error) => {
      captureException(new Error('Failed to prefetch upsell single', { cause: error }));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistantUpsellTestValue, selectedArrow, upsellData?.paywallConfigId]);

  function onGoToNextPage() {
    const nextPageUrl = `/upsell-single-giga/${upsellName}?offerId=${offerId}`;

    analyticsService.setAmplitudeUserProperties({
      web_os_version: getOsWithVersion(),
    });

    navigation.replace(nextPageUrl);
  }

  return (
    <PositionedPageContainer>
      <LanguageNavbar
        isShadow={false}
        isSticky={false}
        isLogoRedirects={false}
      />

      <Wrapper>
        <ContainerWithReviews>
          <ProgressComponent
            steps={progressSteps}
            currentStep={2}
          />

          <CreateAccountComponent
            assistantTest={assistantUpsellTestValue}
            selectedArrow={selectedArrow}
            events={{
              onGoToNextPage,
            }}
          />
        </ContainerWithReviews>
      </Wrapper>
    </PositionedPageContainer>
  );
};
