import { isNil } from 'lodash-es';
import {
  atom,
  selector,
  DefaultValue,
} from 'recoil';

import {
  clearTempUser,
  getTempUserLocally,
  setTempUserLocally,
} from '@/entities/temp-user/model/storage';
import { ITempUser } from '@/entities/temp-user/model/types';

const tempUserTrigger = atom({
  key:     'tempUserTrigger',
  default: 0,
});

export const tempUserQuery = selector<ITempUser | null>({
  key: 'tempUserQuery',
  get: ({ get }) => {
    get(tempUserTrigger);

    return getTempUserLocally();
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue || isNil(value)) {
      clearTempUser();
    } else {
      setTempUserLocally(value);
    }

    set(tempUserTrigger, prev => prev + 1);
  },
});
