import React from 'react';
import ReactDOM from 'react-dom';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// Bottom sheet styles
import 'react-spring-bottom-sheet/dist/style.css';
import { RecoilRoot } from 'recoil';

import App from './App';
import { AuthStore } from './entities/auth';
import './index.css';
import cognitoService from './services/cognito.service';
import { initDayJs } from './utils/date';
import { vhOptimizer } from './utils/vh-optimizer/vh-optimizer';
import './utils/vh-optimizer/vh.css';
import '@/shared/lib/sentry';

initDayJs();
vhOptimizer.init();

const growthBook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_RUNTIME_MODE !== 'production',
  subscribeToChanges: false,
  backgroundSync: false,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

cognitoService
  .getUser()
  .then(user => {
    AuthStore.setEmail(user?.email ?? '');

    return growthBook.setAttributes({
      id: user?.user_id.toString(),
    });
  })
  .finally(() => growthBook.loadFeatures())
  .finally(() => {
    ReactDOM.render(
      <React.StrictMode>
        <GrowthBookProvider growthbook={growthBook}>
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </RecoilRoot>
        </GrowthBookProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
