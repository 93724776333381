import i18next from 'i18next';

import { analyticsService } from '@/services/analytics.service';

type megaUpsellViewType = {
  offerId: string;
  pricingId: string;
  price: number;
  currency: string;
  paymentMethod: string;
  isOneClick: boolean;
  paywall_config_id: string;
  upsellName?: string;
};

export function megaUpsellView(props: megaUpsellViewType) {
  analyticsService.sendEvent('webapp_upsell_view', {
    place:             'signup_multioffer_upsell',
    offer_id:          props.offerId,
    pricing_id:        props.pricingId,
    price:             props.price,
    currency:          props.currency,
    feature:           'signup',
    paywall_config_id: props.paywall_config_id,
    language:          i18next.language,
    payment_method:    props.paymentMethod,
    is_oneclick:       props.isOneClick,
    name:              props.upsellName,
  });
}
