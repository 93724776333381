export interface ProgressStepSignUp {
  title: string;
}

export const progressSteps: ProgressStepSignUp[] = [
  { title: 'Purchase plan' },
  { title: 'Create account' },
  { title: 'Sign up offer' },
  { title: 'Onboarding' },
];
