import { useRecoilValue } from 'recoil';

import { tempUserQuery } from '@/entities/temp-user/model/selector';

export const useTempUser = () => {
  const tempUser = useRecoilValue(tempUserQuery);

  return {
    tempUser,
  };
};
