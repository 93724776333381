import { lazy, useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';

import { setUser } from '@sentry/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userQuery } from '@/context/atoms/User';
import { useTempUser } from '@/entities/temp-user/lib/hooks/useTempUser';
import { tempUserQuery } from '@/entities/temp-user/model/selector';
import { analyticsService } from '@/services/analytics.service';
import { getExistedAppLanguage, setAppLanguage } from '@/utils/Language/Language';
import { getQueryParamsFromURI } from '@/utils/QueryParams/QueryParams';

const LazyUnsubscribePlansSummary = lazy(
  () => import('./Unsubscribe/UnsubscribePlansSummary'),
);
const LazySubscriptionBlock = lazy(
  () => import('./SubscriptionBlock/SubscriptionBlock'),
);
const LazyUnsubscribeQuestions = lazy(
  () => import('./Unsubscribe/UnsubscribeQuestions'),
);

export const SubscriptionPlanRoutes = () => {
  const queryParams = getQueryParamsFromURI();
  const user = useRecoilValue(userQuery);
  const setTempUser = useSetRecoilState(tempUserQuery);
  const { tempUser } = useTempUser();

  const userId = user?.user_id;
  const navigation = useHistory();

  const token = queryParams.token;
  const userIdQuery = queryParams.userId;
  const lang = queryParams.lang;

  useEffect(() => {
    if (lang) {
      setAppLanguage(
        getExistedAppLanguage(lang),
        Number(userIdQuery),
        false,
      );
    }

    if (!token || !userIdQuery) {
      if (userId || tempUser?.id) return;

      navigation.push('/auth/signin');

      return;
    }

    setTempUser({
      token,
      id: userIdQuery,
    });
    setUser({ id: userIdQuery });
    analyticsService.setUserId(userIdQuery);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userIdQuery, navigation, userId, lang]);

  return (
    <Switch>
      <Route
        exact
        path="/profile/plan-subscription/:subscriptionId/unsubscribe/plan"
        component={LazyUnsubscribePlansSummary}
      />
      <Route
        exact
        path="/profile/plan-subscription/:subscriptionId/unsubscribe"
        component={LazyUnsubscribeQuestions}
      />
      <Route
        path="/profile/plan-subscription"
        component={LazySubscriptionBlock}
      />
    </Switch>
  );
};
