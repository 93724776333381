import { ITempUser } from '@/entities/temp-user/model/types';

export const setTempUserLocally = ({ id, token }: ITempUser) => {
  sessionStorage.setItem('tempUser', JSON.stringify({ id, token }));
};

export const getTempUserLocally = (): ITempUser | null => {
  const tempUser = sessionStorage.getItem('tempUser');

  return tempUser ? JSON.parse(tempUser) as ITempUser : null;
};

export const clearTempUser = () => {
  sessionStorage.removeItem('tempUser');
};
