import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useGrowthBook } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import * as yup from 'yup';

import { clearTempUser } from '@/entities/temp-user/model/storage';

import { useUserActions } from '../../../context/actions/user.actions';
import { showLoadingSpinner } from '../../../context/atoms/GlobalPopup';
import { userQuery } from '../../../context/atoms/User';
import { analyticsService } from '../../../services/analytics.service';
import cognitoService from '../../../services/cognito.service';
import LogoNavbar from '../../shared/Navbar/LogoNavbar';

import {
  FlexWrapper,
  QuestionContainer,
  QuestionText,
  StickyButton,
  StyledSpan,
  Subtitle,
  Wrapper,
} from './Signin.styles';

const Signin: FC = () => {
  const [loading, setLoading] = useRecoilState(showLoadingSpinner);
  const growthbook = useGrowthBook();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const userActions = useUserActions();
  const parsedUser = userActions.parseUserString();
  const resetUser = useResetRecoilState(userQuery);
  const { t } = useTranslation();

  const redirectToResetPassword = () => {
    setLoading(true);
    history.push('/auth/reset-password');
    setLoading(false);
  };

  const onSubmit = (data: any) => {
    const email = data.email.trim();
    const re = /\s/;

    if (re.test(email)) {
      setErrorMessage(t('Please enter a valid email')!);

      return;
    }

    if (re.test(data.password)) {
      setErrorMessage('Password cannot contain white space');

      return;
    }

    setErrorMessage('');
    setLoading(true);
    cognitoService.cleanUpCognitoCookies();
    cognitoService
      .signIn(email, data.password)
      .then(() => {
        resetUser();
        clearTempUser();
        analyticsService.sendEvent('pr_funnel_login_finish', {
          success: true,
          email,
          user_id: parsedUser ? parsedUser.id : '',
        });
        cognitoService.getUser().then((user) => {
          if (growthbook) {
            growthbook.setAttributes({
              id: user?.user_id.toString(),
            });
          }
        });
        cognitoService.getSession().then(() => {
          history.push('/');
          setLoading(false);
        });
      })
      .catch((error: any) => {
        analyticsService.sendEvent('pr_funnel_login_finish', {
          success: false,
          email,
          user_id: parsedUser ? parsedUser.id : '',
          error:   error.message,
        });
        switch (error.code) {
          case 'UserNotConfirmedException':
            setErrorMessage(error.message);
            break;
          case 'NotAuthorizedException':
            setErrorMessage(error.message);
            break;
          case 'UserNotFoundException':
            setErrorMessage(t('Seems like you have not registered yet')!);
            break;
          default:
            setErrorMessage(t('Please enter a valid email and password')!);
            break;
        }
        setLoading(false);
      });
  };

  const handleStartQuiz = () => {
    if (typeof window === 'undefined') return;

    window.location.href = process.env.REACT_APP_FUNNEL_10
      ? process.env.REACT_APP_FUNNEL_10
      : 'https://quiz.prosperi.academy';
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        t('* Please enter a valid email')!,
      )
      .required(t('This is a required field')!),
    password: yup
      .string()
      .matches(/[a-z]/, t('* Seems like the password is not correct')!)
      .matches(/\d/, t('* Seems like the password is not correct')!)
      .required(t('* This is a required field')!),
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email:           '',
      username:        '',
      password:        '',
      confirmPassword: '',
    },
    mode:     'onBlur',
    resolver: yupResolver(schema),
  });

  const togglePassword = (event: any) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <>
      <LogoNavbar isShadow={false} />
      <Wrapper>
        <QuestionContainer>
          <QuestionText>{t('Welcome Back')}</QuestionText>

          <FlexWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                {...register('email')}
                onChange={() => setErrorMessage('')}
                sx={{ width: 1 }}
                id="email"
                name="email"
                type="email"
                autoComplete="on"
                label={t('Email')}
                error={getValues('email') !== '' && !!errors.email}
                helperText={getValues('email') !== '' && errors?.email?.message}
                placeholder={t('example@gmail.com')!}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                {...register('password')}
                onChange={() => setErrorMessage('')}
                sx={{ width: 1, height: 40 }}
                id="password"
                type={showPassword ? 'text' : 'password'}
                label={t('Password')}
                name="password"
                autoComplete="on"
                error={getValues('password') !== '' && !!errors.password}
                margin="normal"
                placeholder={t('Enter your password')!}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <button
                      onClick={togglePassword}
                      style={{ all: 'unset' }}
                    >
                      <VisibilityIcon style={{ color: '#6c757d' }} />
                    </button>
                  ),
                }}
              />
              <div className="small">
                {getValues('password') !== '' ? (
                  errors.password ? (
                    errors?.password?.message
                  ) : errorMessage ? (
                    <p>
                      *
                      {t(errorMessage)}
                    </p>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
            </form>

            <StyledSpan>
              <button onClick={redirectToResetPassword}>
                {t('Forgot password?')}
              </button>
            </StyledSpan>
          </FlexWrapper>
        </QuestionContainer>

        <StickyButton>
          <button
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            {t('Sign In')}
          </button>
        </StickyButton>

        <Subtitle>
          {t("Don't have an account?")}
          {' '}
          <span
            style={{
              textDecoration: 'underline',
              color:          '#5653FE',
            }}
            onClick={handleStartQuiz}
          >
            {t('Start the quiz')}
          </span>
        </Subtitle>
      </Wrapper>
    </>
  );
};

export default Signin;
