import { useFeatureValue } from '@growthbook/growthbook-react';

import { getStoredOfferId, getPriceTestPaywallConfigId } from '@/entities/experiments/lib/gigaUpsellOffers';
import { WEEK_1_OFFER_IDS, WEEK_4_OFFER_IDS } from '@/entities/experiments/model/offers';
import { BaseExperimentBuckets } from '@/entities/experiments/model/types';

export const WEEK_1_TEST_NAME = 'giga_upsell_1_week';

export const WEEK_4_TEST_NAME = 'giga_upsell_4_week';

interface UseABTestGigaUpsellProps {
  offerId?: string;
}

/**
 * Hook for getting information about the current state of the "Giga Upsell Pricing" experiment
 * Feature will be disabled if the offer ID is not in the allowed list
 */
export function useABTestGigaUpsellPricing({ offerId }: UseABTestGigaUpsellProps = {}) {
  const activeOfferId = offerId ?? getStoredOfferId() ?? '';

  const { isWeek1, isWeek4 } = {
    isWeek1: WEEK_1_OFFER_IDS.includes(activeOfferId),
    isWeek4: WEEK_4_OFFER_IDS.includes(activeOfferId),
  };

  const testName = isWeek1 ? WEEK_1_TEST_NAME : WEEK_4_TEST_NAME;

  const bucket = useFeatureValue<BaseExperimentBuckets>(
    testName,
    BaseExperimentBuckets.OFF,
  ) as BaseExperimentBuckets;

  const isValidOfferId = activeOfferId
    ? isWeek1 || isWeek4
    : false;

  const paywallConfigId = getPriceTestPaywallConfigId(activeOfferId);

  return {
    bucket,
    isFeatureEnabled: bucket === BaseExperimentBuckets.TEST && isValidOfferId,
    paywallConfigId,
    week1Bucket:      isWeek1 ? bucket : 'default',
    week4Bucket:      isWeek4 ? bucket : 'default',
  };
}
