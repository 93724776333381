import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { userQuery } from '@/context/atoms/User';
import { AuthStore } from '@/entities/auth';
import { SuccessToast } from '@/entities/success-toast';
import { OnboardingPages } from '@/entities/user/model/types';
import { useOnboardingPage } from '@/features/user/lib/hooks/useOnboardingPage';
import CoursivLogo from '@/images/coursiv-logo.svg';
import { WebToAppAnalytics } from '@/modules/web-to-app/analytics';
import { useAppToWeb } from '@/modules/web-to-app/hooks/use-web-to-app';
import { getPlatform } from '@/shared/lib';

import AuthDescriptionImageES from './assets/auth-description-image-es.png';
import AuthDescriptionImage from './assets/auth-description-image.png';
import ForgotPasswordImageES from './assets/forgot-password-image-es.png';
import ForgotPasswordImage from './assets/forgot-password-image.png';

import './web-to-app.css';

const NAVIGATION_STEPS = [
  {
    get title() {
      return i18next.t('web-to-app.navigation-steps.purchase');
    },
    isCurrent: false,
  },
  {
    get title() {
      return i18next.t('web-to-app.navigation-steps.auth');
    },
    isCurrent: false,
  },
  {
    get title() {
      return i18next.t('web-to-app.navigation-steps.upsell');
    },
    isCurrent: false,
  },
  {
    get title() {
      return i18next.t('web-to-app.navigation-steps.download');
    },
    isCurrent: true,
  },
];

const EMAIL = 'support@coursiv.io';
const APP_LINK = process.env.REACT_APP_IOS_URL as string;

let successCopyToastTimeout: ReturnType<typeof setTimeout>;

const hasClipboardApi = Boolean(navigator.clipboard);

const EmailLink = () => <a href={`mailto:${EMAIL}`}>{EMAIL}</a>;

export const WebToAppPage = () => {
  const { t } = useTranslation();
  const [isSuccessCopyToastVisible, setIsSuccessCopyToastVisible] =    useState(false);
  const user = useRecoilValue(userQuery);
  const navigation = useHistory();
  const { token } = useAppToWeb();
  const userId = user?.user_id;
  const cognitoId = user?.cognito_id || '';
  const { sendVisitPage } = useOnboardingPage(userId);

  const platform = getPlatform('ios');

  function hideSuccessCopyToast() {
    clearTimeout(successCopyToastTimeout);
    setIsSuccessCopyToastVisible(false);
  }

  useEffect(() => {
    WebToAppAnalytics.pageView({ platform });

    return () => {
      hideSuccessCopyToast();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openAppLink() {
    if (!userId) return;

    WebToAppAnalytics.onClickDownloadButton({ platform });
    setTimeout(() => {
      window.location.href = `${APP_LINK}?deep_link_sub1=${userId}&deep_link_sub2=${cognitoId}&deep_link_sub3=${token}`;
    }, 300);
  }

  function showSuccessCopyToast() {
    clearTimeout(successCopyToastTimeout);

    setIsSuccessCopyToastVisible(true);

    successCopyToastTimeout = setTimeout(() => {
      setIsSuccessCopyToastVisible(false);
    }, 2000);
  }

  function onWebToAppSkip() {
    navigation.replace('/onboarding/v2');
    WebToAppAnalytics.onSkipButtonClick({ platform });
    sendVisitPage(OnboardingPages.WEB_TO_APP).catch((e) => {
      console.error(e);
    });
  }

  function copyToClipboard(
    type: 'copy_email' | 'copy_password',
    value: string,
  ) {
    WebToAppAnalytics.onClickCopyButton({ action: type, platform });

    navigator?.clipboard
      ?.writeText(value)
      .then(() => {
        showSuccessCopyToast();
      })
      .catch((cause) => {
        throw new Error('ERROR_COPY_TO_CLIPBOARD', { cause });
      });
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="web-to-app-page"
    >
      <header className="web-to-app__header">
        <div
          className="web-to-app__logo"
        >
          <button
            type="button"
            onClick={onWebToAppSkip}
          >
            <img
              width="112"
              height="36"
              src={CoursivLogo}
              alt="coursiv"
            />
          </button>
        </div>

        <nav className="web-to-app__nav">
          <div className="web-to-app__nav-container">
            {NAVIGATION_STEPS.map((step, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`nav-item-${i}`}
                className="web-to-app__nav-item"
              >
                <strong>{step.title}</strong>
                {step.isCurrent ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                  >
                    <rect
                      width="14"
                      height="14"
                      x="1"
                      y="1"
                      fill="#fff"
                      rx="7"
                    />
                    <rect
                      width="14"
                      height="14"
                      x="1"
                      y="1"
                      stroke="#5653FE"
                      strokeWidth="2"
                      rx="7"
                    />
                    <circle
                      cx="8"
                      cy="8"
                      r="4"
                      fill="#5653FE"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                  >
                    <rect
                      width="14"
                      height="14"
                      x="1"
                      y="1"
                      fill="#5653FE"
                      rx="7"
                    />
                    <rect
                      width="14"
                      height="14"
                      x="1"
                      y="1"
                      stroke="#5653FE"
                      strokeWidth="2"
                      rx="7"
                    />
                    <path
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.667"
                      d="M11.333 5.5 6.75 10.083 4.667 8"
                    />
                  </svg>
                )}
              </div>
            ))}
          </div>
        </nav>
      </header>

      <div className="web-to-app__content">
        <h1 className="web-to-app__title">{t('web-to-app.title')}</h1>
        <p className="web-to-app__description">{t('web-to-app.description')}</p>

        <div className="web-to-app__form">
          {AuthStore.email && (
            <label
              className={classNames('web-to-app__input', {
                'web-to-app__input--without-action': !hasClipboardApi,
              })}
            >
              <span>{t('web-to-app.form.email')}</span>
              <input
                type="text"
                readOnly
                value={AuthStore.email}
              />
              {hasClipboardApi && (
                <button
                  type="button"
                  onClick={() => copyToClipboard('copy_email', AuthStore.email)}
                >
                  {t('web-to-app.form.copy')}
                </button>
              )}
            </label>
          )}

          {AuthStore.password && (
            <label
              className={classNames('web-to-app__input', {
                'web-to-app__input--without-action': !hasClipboardApi,
              })}
            >
              <span>{t('web-to-app.form.password')}</span>
              <input
                type="text"
                readOnly
                value={AuthStore.password}
              />
              {hasClipboardApi && (
                <button
                  type="button"
                  onClick={() => copyToClipboard('copy_password', AuthStore.password)}
                >
                  {t('web-to-app.form.copy')}
                </button>
              )}
            </label>
          )}
        </div>

        <div className="web-to-app__auth-description">
          <h3>{t('web-to-app.auth-description.title')}</h3>

          <div className="web-to-app__step">
            <span className="web-to-app__step-counter">1</span>
            <span className="web-to-app__step-description">
              <Trans
                i18nKey={t(
                  `web-to-app.auth-description.steps.first-${platform}`,
                )}
                components={{ b: <b /> }}
              />
            </span>
          </div>
          <div className="web-to-app__step">
            <span className="web-to-app__step-counter">2</span>
            <span className="web-to-app__step-description">
              <Trans
                i18nKey={t('web-to-app.auth-description.steps.second')}
                components={{ b: <b /> }}
              />
            </span>
          </div>
          <div className="web-to-app__step">
            <span className="web-to-app__step-counter">3</span>
            <span className="web-to-app__step-description">
              <Trans
                i18nKey={t('web-to-app.auth-description.steps.third')}
                components={{ b: <b /> }}
              />
            </span>
          </div>

          <div className="web-to-app__image-container">
            <img
              width="312"
              height="160"
              src={
                i18next.language === 'es'
                  ? AuthDescriptionImageES
                  : AuthDescriptionImage
              }
              className="web-to-app__step-image"
              role="presentation"
              alt=""
            />
          </div>

          <div className="web-to-app__step">
            <span className="web-to-app__step-counter">4</span>
            <span className="web-to-app__step-description">
              <Trans
                i18nKey={t('web-to-app.auth-description.steps.fourth')}
                components={{ b: <b /> }}
              />
            </span>
          </div>
        </div>

        <div className="web-to-app__forgot-password-description">
          <h3>{t('web-to-app.forgot-password-description.title')}</h3>
          <p>{t('web-to-app.forgot-password-description.description')}</p>

          <div className="web-to-app__image-container">
            <img
              width="280"
              height="160"
              src={
                i18next.language === 'es'
                  ? ForgotPasswordImageES
                  : ForgotPasswordImage
              }
              role="presentation"
              alt=""
            />
          </div>

          <p>
            <Trans
              i18nKey={t('web-to-app.forgot-password-description.contact-us')}
              components={{
                link: <EmailLink />,
              }}
            />
          </p>
        </div>
      </div>

      <div className="web-to-app__action">
        <button
          type="button"
          onClick={openAppLink}
        >
          {t(`web-to-app.action-${platform}`)}
        </button>
      </div>

      <SuccessToast
        isVisible={isSuccessCopyToastVisible}
        events={{
          onHide: hideSuccessCopyToast,
        }}
      >
        <>{t('web-to-app.copy-action')}</>
      </SuccessToast>
    </motion.section>
  );
};
