import Cookies from 'js-cookie';

import { userApi } from '@/entities/user/api';

import i18n from '../../i18n';
import { languages } from '../../types/Language';

export const getAppLanguageCode = () => {
  if (Cookies.get('sys_lang')) {
    return Cookies.get('sys_lang')!;
  }

  i18n.changeLanguage(getI18NLanguage());

  Cookies.set('sys_lang', getI18NLanguage());

  return getI18NLanguage();
};

export const getExistedAppLanguage = (langCode: string) => {
  const localizedLanguage = languages.find(lang => langCode.includes(lang.code2))?.code2 || 'en';

  return localizedLanguage;
};

export const setAppLanguage = (
  langCode: string,
  userId: number | null,
  isPatch: boolean = true,
) => {
  if (!i18n) return;

  const lang = getExistedAppLanguage(langCode);

  Cookies.set('sys_lang', lang);

  if (isPatch && userId) userApi.changeLanguage(userId, lang);

  i18n.changeLanguage(lang);
};

export const getI18NLanguage = () => {
  if (!i18n.language) return 'en';

  const localizedLanguage = getExistedAppLanguage(i18n.language);

  return localizedLanguage;
};
